


































import {Component, Prop, Vue} from 'vue-property-decorator'
import {ResetPasswordRequest} from '@/model/request/ResetPasswordRequest'
import {InputResetPasswordRequestSchema} from '@/schema/request/ResetPasswordRequest/InputResetPasswordRequestSchema'

@Component
export default class ResetPasswordView extends Vue {
  @Prop({type: [String, Number]}) hash?: string

  schema = new InputResetPasswordRequestSchema()
  request = new ResetPasswordRequest()

  created() {
    this.request.hash = this.hash ?? null
  }

  async submit() {
    await this.request.resetPassword()
    this.$toast.success('system.success.resetPassword')
    await this.$nav.push('/sign-in')
  }
}
